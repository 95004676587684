import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Box, Container, Drawer, List, ListItem, ListItemText, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const Logo = styled('img')({
  background: 'white',
  borderRadius: '50%',
  height: '50px',
});

const NavigationLink = styled(NavLink)(({ theme }) => ({
  color: '#000',
  textDecoration: 'none',
  '&.active': {
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
  },
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

const DrawerContent = styled(Box)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  backgroundColor: '#fff',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 1001,
}));

const NavigationBar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <DrawerContent>
      <CloseButton
        edge="start"
        color="inherit"
        aria-label="close"
        onClick={handleDrawerToggle}
      >
        <CloseIcon />
      </CloseButton>
      <List>
        <ListItem button component={NavigationLink} to="/" onClick={handleDrawerToggle}>
          <ListItemText primary="Home" />
        </ListItem>
        <Divider />
        <ListItem button component={NavigationLink} to="/reviews" onClick={handleDrawerToggle}>
          <ListItemText primary="Reviews" />
        </ListItem>
        <Divider />
        <ListItem button component={NavigationLink} to="/services" onClick={handleDrawerToggle}>
          <ListItemText primary="Services" />
        </ListItem>
        <Divider />
        <ListItem button component={NavigationLink} to="/contact" onClick={handleDrawerToggle}>
          <ListItemText primary="Contact" />
        </ListItem>
        <Divider />
        <ListItem button component={NavigationLink} to="/payments" onClick={handleDrawerToggle}>
          <ListItemText primary="Payments" />
        </ListItem>
        <Divider />
        <ListItem button component={NavigationLink} to="/donations" onClick={handleDrawerToggle}>
          <ListItemText primary="Donations" />
        </ListItem>
        <Divider />
        <ListItem button component={NavigationLink} to="/about" onClick={handleDrawerToggle}>
          <ListItemText primary="About" />
        </ListItem>
      </List>
    </DrawerContent>
  );

  return (
    <AppBar position="sticky" sx={{ backgroundColor: '#000' }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <NavLink to="/" >
            <Logo src="logo4.png" alt="Logo" />
          </NavLink>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Container>
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        transitionDuration={500}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default NavigationBar;
