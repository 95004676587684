import React from 'react';
import Spline from '@splinetool/react-spline';
import './SplineBackground.css'; // Import the CSS for styling

const SplineBackground = () => {
  return (
    <div className="spline-background">
      <Spline scene="https://prod.spline.design/qrugnJBa6mDO2W6c/scene.splinecode" />
    </div>
  );
};

export default SplineBackground;
