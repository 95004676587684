import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Luckiest Guy',
      'cursive',
    ].join(','),
    h1: {
      fontFamily: 'Luckiest Guy, cursive',
      
    },
    h2: {
      fontFamily: 'Luckiest Guy, cursive',
    },
  },
  palette: {
    primary: {
      main: '#ff4081',
    },
    secondary: {
      main: '#e040fb',
    },
  },
});

export default theme;
